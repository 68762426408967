/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from "react"
import styled from "styled-components"
// import moment from "moment"
import { get } from "lodash"

import { getSubscriptionFeature as getFeature } from "../store"
import authStore from "../stores/auth"
import { toastSuccess, toastError } from "../utils/myToasts"
import root from "../utils/windowOrGlobal"

import renewLogo from "../imgs/renew-proxy-grey.svg"
import picLogo from "../imgs/pic.png"
import everestLogo from "../imgs/everest.png"
import Button from "../pages/settings/components/_Button"
import { upgradeToManual, changeLocationMultiProduct } from "../utils/CallAPIs"
import IPAuthorization from "./IPAuthorization"

import ProxyInfo from "./ProxyInfo/ProxyInfo"
import { formatDatetoEST } from "../utils/dateformat"
import MultiLocationProductDetailHeader from "./MultiLocationProductDetailHeader"

const Input = styled.input`
  border: 1px solid #9ba1aa;
  border-radius: 4px;
  width: 194px;
  padding: 10px 20px;
  margin-top: 12px;
`
const MyManageProxyApp = styled.div`
  margin: 0 auto;
  padding: 0 10px 0 10px;
  flex: none;
  display: block;

  @media (max-width: 960px) {
    width: 80%;
    padding: 0;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
  }
  @media (min-width: 961px) {
    width: 52%;
  }

  .a-container {
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    will-change: transform, box-shadow;
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: block;
    padding: 15px 0;
    position: relative;
    min-height: 384px;
    margin: 0 auto 24px;

    user-select: none;
    border-radius: 12px;
  }

  .ccc1 {
  }

  .ccc1a {
    justify-content: center;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4 auto;
  }

  .ccc1a-span {
    margin-right: 9px;
  }

  .ccc1as-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    // border-radius: 50%;
    overflow: hidden;
    position: relative;
    user-select: none;
  }

  .ccc1asd-1 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  .ccc1asd-1a {
    display: block;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    position: relative;
  }

  .renew-proxy-img {
    height: 100%;
  }
  .ccc1a-para {
    color: #9ba2ac;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
  }

  .ccc1b {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .ccc1b-img-div {
    margin: 20px auto;
    width: auto;
    display: table;
    position: relative;
  }

  .ccc1bid-img {
    height: 70px;
  }

  .ccc1b-h3 {
    color: #419bf9;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 1px;
    margin-top: 0px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
  }

  p {
    color: #6abf83;
    margin: 0;
    text-align: center;
  }

  p.font-color-black {
    color: #333333;
    margin: 10px 0 5px 0;
    text-align: center;
  }

  h5,
  a {
    font-size: 16px;
    line-height: 150%;
    color: #419bf9;
    margin: 0 0 0 0;
    text-align: center;
    text-decoration: none;
  }

  .small {
    font-size: 14px;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const MyPti = styled.div`
  display: flex;
  flex-flow: column;
  margin: 10px 20px;

  .pti-left {
    padding: 10px;
    display: inline-table;
    margin: 0 auto;
  }

  .pti-right {
    display: flex;
    flex-flow: column;
    padding: 10px;
    display: inline-table;
    margin: 0 auto;
  }
`

const MyCancelDiv = styled.div`
  width: 30%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  color: purple;
  font-size: 13px;
  &:active {
    background-color: yellow;
  }
  &:hover {
    font-weight: 700;
  }
`

const IPRotationContainer = styled.div`
  .row {
    display: flex;
    justify-content: center;
  }

  button {
    margin-top: 10px;
  }

  button:nth-child(n + 2) {
    margin-left: 20px;
  }
`

class IPRotation extends React.Component {
  state = {
    timer: 0,
    intervalEditable: false,
    loading: false,
    rotateClicked: false,
    forceRender: "",
    currentExtIp: null,
  }

  pingInterval = 5000

  get IPRotationURL() {
    const { subscription } = this.props
    const apiKey = get(authStore, "state.user.api_key")

    return apiKey
      ? `${process.env.RP_BASE_URL}/proxy/${subscription.id}/rotate_ip?api_key=${apiKey}`
      : null
  }

  checkIP = async timer => {
    const { fetchSubscriptions } = this.props
    const allSubscriptions = await fetchSubscriptions()
    const subscription = allSubscriptions.find(
      ({ id }) => id === this.props.subscription.id
    )

    if (timer > 0) {
      if ("Reset" === get(subscription, "modem.resetStatus")) {
        this.setState({ rotateClicked: false })

        if (false === get(subscription, "modem.lastResetSuccess")) {
          return toastError(
            `Oops, the rotate action failed! Our team has been notified about this issue. Please try again later.`
          )
        }
        if (
          subscription.modem.extIp &&
          subscription.modem.extIp === this.state.currentExtIp
        ) {
          return
        }
        this.setState({ currentExtIp: subscription.modem.extIp })
        return toastSuccess(
          `IP rotated successfully! Your new IP is ${subscription.modem.extIp}`
        )
      }

      const remainingTime = timer - this.pingInterval / 1000
      root.setTimeout(() => this.checkIP(remainingTime), this.pingInterval)
    } else {
      // Silently fail for now.
      // @TODO: add API call to notify backend
      return toastError(
        `Oops, the rotate action is taking longer time than usual! Please refresh page and check again.`
      )
    }
  }

  toggleInterval = () =>
    this.setState(({ intervalEditable }) => ({
      intervalEditable: !intervalEditable,
    }))

  handleRotate = e => {
    e.preventDefault()
    const { rotateIP, subscription } = this.props
    this.setState({ rotateClicked: true })
    rotateIP(subscription.modem)
    root.setTimeout(() => this.checkIP(300), this.pingInterval)
  }

  handleIntervalSubmit = async e => {
    e.preventDefault()
    const autoRotationTime = parseInt(this.intervalRef.value)
    if (autoRotationTime < 2) {
      return toastError("Auto-rotation interval should be 2 minutes or more")
    }
    const { subscription, updateAutoRotationInterval } = this.props
    this.setState({ loading: true })
    await updateAutoRotationInterval(subscription.id, autoRotationTime)
    this.setState({ loading: false, intervalEditable: false })
  }

  hanldeDelete = async () => {
    if (
      !root.confirm("Are you sure you want to remove automatic IP rotation?")
    ) {
      return
    }
    const { subscription, removeAutoRotationInterval } = this.props
    this.setState({ loading: true })
    await removeAutoRotationInterval(subscription.id)
    this.setState({ loading: false, intervalEditable: false })
  }

  handleUpgradeToManual = async () => {
    try {
      const result = await upgradeToManual(this.props.subscription.id)
      if (result.data.session) {
        if (
          !window.confirm(
            "You don't have enough credit to upgrade to Manual Ip Rotation plan. \n" +
              "You will be redirect to checkout page to add missing credit"
          )
        )
          return toastError(
            "You don't have enough credit to upgrade to Manual Ip Rotation plan"
          )

        if (window.Stripe) {
          const stripe = window.Stripe(process.env.STRIPE_API_KEY)
          return stripe.redirectToCheckout({ sessionId: result.data.session })
        }

        return toastError("Fail to add credit to your account")
      }
      toastSuccess("Upgrade Success")
      return this.props.reload()
    } catch (error) {
      console.error(error)
      const message = `${get(
        error,
        "response.data.errors.0.title",
        "Oops! We couldn't process your request!"
      )}-${get(error, "response.data.errors.0.detail", "")}`
      return toastError(message)
    }
  }
  componentDidMount() {
    const { subscription } = this.props
    const isRotating = "Resetting" === get(subscription, "modem.resetStatus")

    if (isRotating && subscription.autoRotationTime === null) {
      root.setTimeout(() => this.checkIP(300), this.pingInterval)
    }

    this.setState({ currentExtIp: get(subscription, "modem.extIp", null) })
  }

  render() {
    const { subscription } = this.props
    const { intervalEditable, loading, rotateClicked } = this.state
    const isRotating =
      rotateClicked || "Resetting" === get(subscription, "modem.resetStatus")

    return subscription.ipRotatable ? (
      <IPRotationContainer>
        <h5>Your IP</h5>
        <p className="font-color-black">{subscription.modem.extIp}</p>
        {subscription.modem.lastResetAt && (
          <p
            style={{
              color: "black",
              fontSize: "11px",
              fontStyle: "italic",
            }}
          >
            updated -{" "}
            {new Date(subscription.modem.lastResetAt).toLocaleString()}
          </p>
        )}

        {subscription.ipWhitelist && (
          <IPAuthorization
            id={subscription.id}
            ipWhitelist={subscription.ipWhitelist}
            reload={this.props.reload}
          />
        )}
        <React.Fragment>
          {!subscription.isTrial && (
            <>
              <hr />
              <h5>IP Rotation</h5>
              <p className="small font-color-black">
                Set the time interval for automatic change (rotation) of IP.
              </p>

              {intervalEditable ? (
                <form onSubmit={this.handleIntervalSubmit}>
                  <div className="row">
                    <Input
                      ref={ref => (this.intervalRef = ref)}
                      placeholder="10 mins"
                      type="number"
                      min="2"
                      disabled={loading}
                      defaultValue={subscription.autoRotationTime}
                    />
                  </div>
                  <div className="row">
                    <Button disabled={loading} onClick={this.toggleInterval}>
                      Cancel
                    </Button>
                    <Button
                      disabled={loading}
                      primary
                      type="submit"
                      onClick={this.handleIntervalSubmit}
                    >
                      Apply
                    </Button>
                  </div>
                </form>
              ) : (
                <>
                  {subscription.autoRotationTime === null ? (
                    <div className="row">
                      <Button
                        disabled={loading}
                        onClick={this.toggleInterval}
                        primary
                      >
                        Set
                      </Button>
                    </div>
                  ) : (
                    <>
                      <p className="small font-color-black">
                        Every {subscription.autoRotationTime} min
                      </p>
                      <div className="row">
                        <Button disabled={loading} onClick={this.hanldeDelete}>
                          Delete
                        </Button>
                        <Button
                          primary
                          disabled={loading}
                          onClick={this.toggleInterval}
                        >
                          Update
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <hr />
          <h5>Change (Rotate) IP</h5>
          <div className="row">
            <Button primary onClick={this.handleRotate} disabled={isRotating}>
              {isRotating ? "Rotating..." : "Rotate IP Now"}
            </Button>
          </div>
          {this.IPRotationURL && (
            <React.Fragment>
              <div>
                <hr />
              </div>
              <p className="small font-color-black">
                You can also rotate this IP through this unique URL:
                <br />
                <br />
                <a className="small" href={this.IPRotationURL}>
                  {subscription.id}
                </a>
              </p>
            </React.Fragment>
          )}
        </React.Fragment>
      </IPRotationContainer>
    ) : (
      <IPRotationContainer>
        {subscription.ipWhitelist && (
          <IPAuthorization
            id={subscription.id}
            ipWhitelist={subscription.ipWhitelist}
            reload={this.props.reload}
            isTrial={subscription.isTrial}
            hideTopDiviner
          />
        )}
        <h5>IP Rotation</h5>
        <div className="row">
          <Button onClick={this.handleUpgradeToManual} primary>
            Upgrade to Manual
          </Button>
        </div>
      </IPRotationContainer>
    )
  }
}

const Container = styled.div`
  .banner {
    text-align: center;
    padding: 16px;
    background-color: #6579f3;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #ffffff;
  }
`
let captchaRef = null
let _widgetID = undefined
export default function ManageProxyApp(props) {
  const {
    id,
    allSubscriptions,
    rotateIP,
    cancelMPManagedSubscription,
    locations,
    fetchLocations,
  } = props
  const { fetchSubsLink, fetchSubscriptions } = props

  const [showBanner, setBanner] = useState(false)
  const [wasReload, forceReRender] = useState("")
  const [greToken, setGreToken] = useState(null)
  const [isSwitching, setIsSwitching] = useState(false)
  const isMPManagedUser = get(
    authStore,
    "state.user.mp_managed_subscription",
    false
  )

  const renderRecaptchaV2 = () => {
    if (window.grecaptcha && _widgetID === undefined) {
      // console.log("On renderRecaptchaV2", !!window.grecaptcha.render)
      const wrapper = document.createElement("div")

      _widgetID = window.grecaptcha.render(wrapper, {
        sitekey: process.env.GRECAPTCHA_V2_KEY,
        callback: handleRecaptchaV2Response,
        theme: "light",
      })

      captchaRef.appendChild(wrapper)
    } else {
      // console.log("renderRecaptchaV2 fail")
    }
  }
  const fetchSubsWithRecaptcha = async () => {
    let _greToken, v2
    if (greToken) {
      // console.log("initial greToken present", greToken)
      v2 = true
      _greToken = greToken
      fetchSubsLink(_greToken, v2)
    } else {
      // console.log("initial greToken missing", greToken)
      v2 = false
      try {
        _greToken = await window.grecaptcha.execute(
          process.env.GRECAPTCHA_KEY,
          {
            action: "submit",
          }
        )
        // console.log("v2 greToken", _greToken)
        fetchSubsLink(_greToken, v2)
      } catch (e) {
        console.error(e)
        console.error("Fallback to recaptcha v2")
        return renderRecaptchaV2()
      }
    }
  }
  const handleRef = ref => {
    if (!ref) {
      // console.log("no ref===", 1)
      return
    }
    // console.log("has ref")
    captchaRef = ref
  }
  useEffect(() => {
    fetchLocations()
  }, [])
  useEffect(() => {
    if (id) fetchSubsWithRecaptcha()
  }, [id, fetchSubsLink])

  useEffect(() => {
    fetchSubscriptions()
  }, [wasReload, fetchSubscriptions])
  useEffect(() => {
    return () => {
      // console.log("unmounting", !!window.grecaptcha)
      if (window.grecaptcha && _widgetID) window.grecaptcha.reset(_widgetID)
    }
  })
  const handleRecaptchaV2Response = greToken => {
    // console.log("handling v2 response")
    fetchSubsLink(greToken, true)
    setGreToken(greToken)
  }

  const handleCancelProxy = () => {
    cancelMPManagedSubscription(subscription.id)
  }

  const subscription =
    Array.isArray(allSubscriptions) && allSubscriptions.find(_ => _.id === id)

  if (!subscription || !subscription.modem) {
    return null
  }
  const renewInDays = formatDatetoEST(subscription.autoRenewAt)
  const expiresInDays = formatDatetoEST(subscription.expiredAt)
  const showDays = !!subscription.autoRenewAt || !!subscription.expiredAt
  const proxy = getFeature(subscription, "Proxy Type")

  const handleSwitch = async (locationId, proxyTypeId) => {
    try {
      setIsSwitching(true)
      const result = await changeLocationMultiProduct(
        subscription.id,
        locationId,
        proxyTypeId
      )

      if (result) {
        toastSuccess("Location switched successfully!")
      }
      setIsSwitching(false)
    } catch (err) {
      setIsSwitching(false)
      console.error("Something went wrong", err)
    }
  }

  return (
    <Container>
      <div className="recaptcha-container" ref={handleRef} />
      {subscription.cancellable && showBanner && (
        <div className="banner" onClick={() => setBanner(false)}>
          You can unsubscribe from the purchased proxy and get prorated credit
          to use to buy a separate proxy within the first three days after
          purchase.
          <br /> If you'd like to cancel, click “Cancel Proxy” at the bottom of
          this page.
        </div>
      )}
      <MyManageProxyApp>
        <div className="card-container">
          {subscription.multilocationProduct ? (
            <MultiLocationProductDetailHeader
              subscription={subscription}
              locations={locations}
              handleSwitch={handleSwitch}
              isSwitching={isSwitching}
            />
          ) : (
            <>
              <div className="ccc1">
                <div className="ccc1b-img-div">
                  <img
                    className="ccc1bid-img"
                    src={picLogo}
                    alt="Proxy Network"
                  ></img>
                </div>
                <div className="ccc1b">
                  <h3 className="ccc1b-h3">
                    {subscription.nickName || proxy.title}
                  </h3>
                  {subscription.location && (
                    <strong className="small">
                      {subscription.location.city},{" "}
                      {subscription.location.state}
                    </strong>
                  )}
                </div>
              </div>
              <div className="ccc1a">
                <span className="ccc1a-span">
                  <div className="ccc1as-div">
                    <div className="ccc1asd-1">
                      <div className="ccc1asd-1a">
                        <img
                          className="renew-proxy-img"
                          src={renewLogo}
                          alt="Plan renews"
                        ></img>
                      </div>
                    </div>
                  </div>
                </span>
                {showDays && (
                  <p className="ccc1a-para">
                    {subscription.autoRenewAt
                      ? "Renewal date: " + renewInDays
                      : `Expiry date: ${expiresInDays}`}
                  </p>
                )}
              </div>
              <MyPti>
                <div className="pti-left">
                  <img src={everestLogo} height="40" alt="Proxy Type"></img>
                </div>
                <div className="pti-right">
                  <h5>{proxy.title}</h5>
                  {proxy.servicesOffered.split(",").map((service, i) => (
                    <p key={i} className={i === 0 ? "font-color-black" : ""}>
                      {service}
                    </p>
                  ))}
                </div>
              </MyPti>
            </>
          )}

          <MyPti>
            <ProxyInfo
              {...subscription.modem}
              subId={subscription.id}
              reload={forceReRender}
              isTrial={subscription.isTrial}
              pgConnection={subscription.pgConnection}
              multilocationProduct={subscription.multilocationProduct || false}
            />
            <div>
              <hr />
            </div>
            <IPRotation
              subscription={subscription}
              rotateIP={rotateIP}
              fetchSubscriptions={props.fetchSubscriptions}
              updateAutoRotationInterval={props.updateAutoRotationInterval}
              removeAutoRotationInterval={props.removeAutoRotationInterval}
              reload={forceReRender}
            />
            {subscription.autoRenewAt && (
              <React.Fragment>
                <div>
                  <hr />
                </div>
                {isMPManagedUser && (
                  <p onClick={handleCancelProxy}>
                    <MyCancelDiv>Cancel Proxy</MyCancelDiv>
                  </p>
                )}
                {!isMPManagedUser && (
                  <a href={props.manageSubsLink}>
                    <MyCancelDiv>Cancel Proxy</MyCancelDiv>
                  </a>
                )}
              </React.Fragment>
            )}
          </MyPti>
        </div>
      </MyManageProxyApp>
    </Container>
  )
}
